#titles {
  text-align: center;
  color: white;
  margin-top: 10%;
  margin-left: 10%;
  font-family: 'Montserrat', sans-serif;
}

@media screen and (min-width: 800px) {
  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: large;
    font-family: 'Montserrat', sans-serif;
  }

  /* Centered text */
  .centered-white {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: large;
    font-family: 'Montserrat', sans-serif;
  }


}

/* Container holding the image and the text */
.container {
  position: relative;
  text-align: center;
  color: white;
  max-width: 100%;
  vertical-align: middle;
  display: table-cell;
}

#footerImage {
  max-height: 5%;
}

.footer {
  margin-top: 5%;
  text-align: center;
}

#loading {
  text-align: center;
  color: white;
}

#page {
  margin:5%;
}

#results {
  margin-left: 3%;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
}

body {
  background: #262626;
}

.jumbotron{
  background: url("./images/header-background.png") no-repeat center center;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  margin: 0;
}

.revoke-full {
  background: url("./images/REVOKE_FULL_SIZE_NEON.png") no-repeat center center;
}

.revoke-small {
  background: url("./images/REVOKE_SMALL_SIZE_NEON.png") no-repeat center center;
}

.allowance {
  overflow: hidden;
}

#columns {
  background-size: auto;
  padding: 5%;
  background: url("./images/toolbar.png") no-repeat;
}

#headers {
  margin-top: -2%;
}

#revokeLink {
  color: white;
}
